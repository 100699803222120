export let config = {
  DEFAULT_LANGUAGE: "en",
  DEFAULT_LOCALE: "en",
};

export async function loadConfig(): Promise<void> {
  config = {
    DEFAULT_LANGUAGE: "en",
    DEFAULT_LOCALE: "en",
  };
}
